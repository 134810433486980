import React, { useState, useEffect, useRef } from 'react';
import './ContactPage.scss';
import emailjs from '@emailjs/browser';
import RINGS from "vanta/dist/vanta.rings.min";
import * as THREE from "three";

const ContactPage = () => {
const formRef = useRef(null);

const [name, setName] = useState('');
const [email, setEmail] = useState('');
const [message, setMessage] = useState('');


const handleSubmit = async (e) => {
  e.preventDefault(); //prevent reload

//EmailJS service ID, template ID, and Public Key
const serviceId = 'service_nkbcpke';
const templateId = 'template_ndju7cr';
const userId = 'OeDkgEJu1PGE71pbZ';

emailjs.init(userId);
  // Add your form submission logic here, such as sending the data to a server

const templateParams = {
  from_name: name,
  from_email: email,
  to_name: 'Carolaine Bonk',
  message: message,
}

try { 
  const response = await emailjs.send(serviceId, templateId, templateParams);
    console.log('Email sent succesfully!', response);
    setName('');
    setEmail('');
    setMessage('');
  } 
  catch(error) {
    console.log('Error sending email:', error);
  }
}

const handleClick = () => {
  alert('Thank you for connecting with me. Your message has been sent!');
} 

useEffect(() => {
  const vantaEffect = RINGS({
    THREE,
    el: "#background_container",
    mouseControls: true,
    touchControls: true,
    gyroControls: false,
    minHeight: 200.00,
    minWidth: 200.00,
    scale: 1.00,
    scaleMobile: 1.00,
    backgroundColor: 0x0,

  });

  return () => {
    if (vantaEffect) vantaEffect.destroy();
  };
}, []);

    return (
      <div className="contact-page">
            <div className="contact">Let's talk</div>
            <div className="boxy">
        <div className="form">
        <div className="sub-title">
        You can reach me by filling out the form below</div>
          <form ref={formRef} onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              value={name}
              required
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            />
            <textarea
              name="message"
              placeholder="Your Message"
              value={message}
              rows={10}
              required
              onChange={(e) => setMessage(e.target.value)}
            />
            <div className="button-container">
            <button onClick={handleClick} type="submit">Send Message</button>
            </div>
          </form>
        </div>
        </div>
      </div>
    );
  };
  
  export default ContactPage;
