import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import * as THREE from "three";
import CLOUDS from "vanta/dist/vanta.clouds.min";
//import { Link } from "react-router-dom";
import Navigation from '../../components/navigation/Navigation';
import Share from "../../components/share/Share";
import axios from "axios";
import { format } from 'date-fns';
import "./BlogPostPage.scss";


function BlogPostPage() {
  const vantaRef = useRef(null);
  const [error, setError] = useState(null);
  const [posts, setPosts] = useState(null);
  const [post, setPost] = useState(null);
  const location = useLocation();
  const postId = location.state?.postId;
  const blogId = location.state?.blogId;


    // Define a function to format the date
    const formattedDate = (dateString) => {
      const date = new Date(dateString);
      return format(date, 'MMMM d, yyyy'); // Customize the format as needed
    };

    function fetchDataWithAxios(postId, blogId) {
      return axios.get(
        `https://www.googleapis.com/blogger/v3/blogs/3976487151389696370/posts/${postId}?key=AIzaSyA7Oz494wjCaEVfT1AcF5jOMcUyq_LTNJY`
      
      ).catch(error => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      }
      
      );
    }
    
    function fetchPosts(postId) {
      // Adjust the API endpoint to handle paginations and
      return axios.get(
        `https://www.googleapis.com/blogger/v3/blogs/3976487151389696370/posts?key=AIzaSyA7Oz494wjCaEVfT1AcF5jOMcUyq_LTNJY`
      
      ).catch(error => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      }

      );
    }

useEffect(() => {

  console.log(location.state);

  fetchDataWithAxios(postId, blogId)
  .then((response) => {
    console.log(response);
        if (response.data) {
         // Assuming 'data' is the blog posts in the response

        setPost(response.data); 
        fetchPosts(blogId).then((postsResponse) => {
          setPosts(postsResponse.data.items);
        });
   
    } else {
        console.log("No posts found");
      }
      })
      .catch ((error) => {
        setError('Failed to fetch data');
        console.error("Fetch error:", error);
      });
}, [postId, blogId, location.state]);

useEffect(() => {
  
  fetchPosts(blogId).then((postsResponse) => {
    if (postsResponse.data && postsResponse.data.items) {
      setPosts(postsResponse.data.items);
    } else {
      console.log("No posts found");
    }
  }).catch((error) => {
    console.error("Fetch error:", error);
  });
}, [blogId]);

  useEffect(() => {
    vantaRef.current = CLOUDS({
      THREE,
      el: "#background_container",
      color: 0x00ffff,
      backgroundColor: 0x222222,
      blur: 10,
      zoom: 3.25,
    });
      return () => {
        if (vantaRef.current) {
          vantaRef.current.destroy();
        }
      };
    }, []); 

  return (
<>
    <div className="blog-page">
    {post &&  (
    <article>
      <p className="blog-intro">
      {console.log("Published:", post)}
      </p>
      <div className="blog-head">
      <div className="blog-title">{post.title}</div>
      <div className="blog-description">
      {post.author && (
      <div className="blog-author">
      Writting by {post.author.displayName}
      </div>
    )}
      <div className="blog-date">
      Published on {formattedDate(post.published)}
      </div>
      <Share description={post.description} />
      </div>
      </div>
      <div className="blog-content">
      <div dangerouslySetInnerHTML={{ __html:post.content}} />
    </div> 
    </article>
    )}
      <Navigation/>
      </div>
    </>
  )};

export default BlogPostPage;
