import {useRef, useEffect} from "react";
import "./Aboutpage.scss";
//import { Link } from "react-router-dom";
import cvPath from "../../assets/docs/Carolaine_Bonk_Resume-2024.2.pdf";
import { Parallax, ParallaxLayer } from '@react-spring/parallax';
import { useSpring, animated } from "@react-spring/web";
import p5 from 'p5';
import TRUNK from "vanta/dist/vanta.trunk.min";
import Navigation from '../../components/navigation/Navigation';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

function Aboutpage() {
  const parallaxRef = useRef(null);
  const p5Ref = useRef(null); // Ref for p5 sketch
  const props = useSpring({opacity: 0.5 && 1, from: {opacity: 0}})

  useEffect(() => {
      const vantaEffect = TRUNK({
        p5,
        el: "#background_container",
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: 200.00,
        minWidth: 200.00,
        scale: 1.00,
        scaleMobile: 1.00,
        backgroundColor: 0x0,
      });  


    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, []);


  const openCv = () => {
    window.open(cvPath, "_blank");
  };

  return (
    <>
    <div id="background_container" ref={p5Ref} style={{ width: '100%', height: '100vh' }}></div>
      <section className="main-container">
      <Parallax ref={parallaxRef} pages={3.6}>
      <ParallaxLayer 
      className="center"
      speed={0.05}
      offset={0}
      factor={1}
      onClick={()=> ('')}>

          <div className="upper-container">
            <div className="project-title">About Bonk</div>
            <div className="boxy">
              <p>
                <animated.i style={props}>creativity is where fun lives</animated.i>
              </p>
            <div className="description">
              Creativity and innovation have always been important to me and I
              believe software development is the ultimate form of boundless
              creation. I consider my imagination, communication skills and
              design abilities to be my strongest assets. I think of myself as
              having a natural good sense for aesthetics, and good experience in
              responsive/mobile-first web design.
            </div>
            </div>
            <div className="button-container">
            <button onClick={() => parallaxRef.current.scrollTo(1)}>
            MY EXPERIENCES</button>
            <button onClick={openCv}>OPEN RESUME</button>
            </div>
            </div>
            </ParallaxLayer>
            <ParallaxLayer 
      offset={1.1} 
      speed={0.45}
      factor={1.5}
      style={{
        backgroundSize: 'cover', backgroundBlur:5,
      }} 
      onClick={()=> ('')}>
              <div className="timeline-container-wrapper">
            <div className="projects-title">
            <div className="subTitle">My Experiences</div>
              <p>
                <animated.i style={props}>A brief resume of my experiences</animated.i>
              </p>
            </div>
            
          <div className="timeline-container">
            <VerticalTimeline>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: "rgba(0, 0, 8, 0.288)",
                  color: "rgb(255, 255, 255)",
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  rgb(33, 150, 243)",
                }}
                date="2023 - present"
                dateStyle={{ color: "FFF" }}
                iconStyle={{ background: "rgb(142, 65, 89)" }}
                //icon={}
              >
                <h3 className="vertical-timeline-element-title">
                  Web Developer at CloudBrewers LLC
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                  Miami, FL
                </h4>
                <p className="desc-experience">                            
                  Specialized in leveraging platforms such as Wix, Velo Code and
                  Zapier automations to build highly functional and visually
                  stunning websites. For more:{" "}
                  <a
                    href="https://www.cloudbrewers.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    cloudBrewers
                  </a>
                </p>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                className="vertical-timeline-element--education"
                contentStyle={{
                  background: "rgba(0, 0, 8, 0.288)",
                  color: "rgb(255, 255, 255)",
                }}
                date="2022"
                iconStyle={{
                  background: "rgb(211, 247, 8)",
                  color: "antiquewhite",
                }}
                //icon={}
              >
                <h3 className="vertical-timeline-element-title">
                  Full-Stack Web Development Bootcamp
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                  BrainStation's Full-Stack Web Development diploma program
                </h4>
                <p className="desc-experience">  
                  {" "}
                  More than 1000 hours of programming experience during an
                  intensive Web Development diploma program; Developed a total
                  of 8 different applications, participated in 3 hackathons and
                  created 2 APls using HTML5, BEM, CSS3, JavaScript (ES6),
                  React, and SASS for the front-end and back-end development
                  utilizing Node, Express, MySQL and Knex. Skills: REST APIs ·
                  OAuth · Agile Methodologies.
                </p>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: "rgba(0, 0, 8, 0.288)",
                  color: "rgb(255, 255, 255)",
                }}
                date="2018 - 2020"
                iconStyle={{ background: "rgb(142, 65, 89)" }}
                //icon={}
              >
                <h3 className="vertical-timeline-element-title">
                  Server at Kone Sushi
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                  Miami, FL
                </h4>
                <p className="desc-experience">  
                  Being a waitress is a high-pressure job that requires tact,
                  patience and solid people skills. I also learned a lot about
                  multitasking and teamwork.
                </p>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: "rgba(0, 0, 8, 0.288)",
                  color: "rgb(255, 255, 255)",
                }}
                date="2016"
                iconStyle={{ background: "rgb(142, 65, 89)" }}
                //icon={}
              >
                <h3 className="vertical-timeline-element-title">
                  Logistics Assistant | ARD & ZDF
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                  Rio de Janeiro, Brazil
                </h4>
                <p className="desc-experience">  
                  Attentive to details in a fast-paced environment, while
                  interacting with different cultures in a diverse international
                  setting - during the Olympics and Paralympics of 2016. I
                  learned extensively about interpersonal communication.
                </p>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: "rgba(0, 0, 8, 0.288)",
                  color: "rgb(255, 255, 255)",
                }}
                date="2015 - 2017"
                iconStyle={{ background: "rgb(142, 65, 89)" }}
                //icon={}
              >
                <h3 className="vertical-timeline-element-title">
                  Marketing Manager | QUARTOMUNDO
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                  Rio de Janeiro, Brazil
                </h4>
                <p className="desc-experience">  
                  Creating engagement, community and building bridges, with a
                  track of building sustainable relationships. Connecting the
                  startup with potential investors, and with the game developer
                  community in Rio de Janeiro.
                </p>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                className="vertical-timeline-element--education"
                contentStyle={{
                  background: "rgba(0, 0, 8, 0.288)",
                  color: "rgb(255, 255, 255)",
                }}
                date="2015"
                iconStyle={{ background: "rgb(211, 247, 8)" }}
                //icon={}
              >
                <h3 className="vertical-timeline-element-title">
                  Bachelors in Defense and International Strategic Management
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                  at UFRJ - RJ/Brazil
                </h4>
                <p className="desc-experience">  
                  Emphasis on International Business Researcher at COPPEAD/UFRJ
                  - From May/2014 to May/2015, I was researching International
                  Entrepreneurship, theories, Incubators, Accelerators, Social
                  Networks, Start-Ups and Effectuation Theory. For more details,
                  please read the full research paper:{" "}
                  <a
                    href="https://www.researchgate.net/profile/Carlos-Augusto-Septimio-De-Carvalho/publication/320934341_Effectuation_and_the_influence_of_social_networks_on_the_internationalization_of_accelerated_startups/links/5a034abcaca2721d41865f8e/Effectuation-and-the-influence-of-social-networks-on-the-internationalization-of-accelerated-startups.pdf"
                    target="_blank"
                    decoration="none"
                    backgroundcolor="#FFFFFF"
                    rel="noopener noreferrer"
                  >
                    read paper.
                  </a>
                </p>
              </VerticalTimelineElement>
            </VerticalTimeline>
          </div>
          <div className="boxie">
              {/* <button onClick={() => parallaxRef.current.scrollTo(0)}>
              GO BACK UP</button> */}
              <Navigation/> 
          </div>
        </div> 
        </ParallaxLayer> 
        </Parallax>
      </section>
    </>
  );
}

export default Aboutpage;
