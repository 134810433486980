import React from 'react';
import { useHistory } from 'react-router-dom';
import './Navigation.scss';

const Navigation = () => {
    const history = useHistory();

    const goBack = () => {
        history.goBack();
    };

    return (
        <div className="navigation">
            <button onClick={goBack}><i className="fa-solid fa-arrow-left"></i></button>
        </div>
        )
    };

    export default Navigation;
