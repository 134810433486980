import React, { useEffect, useState } from 'react';
import './BlogAnimation.scss'; // Import your CSS file

const BlogAnimation = () => {
  const textToType = "Bringing my insights on becoming #futureproof as a Developer in the Age of AI";
  const [typedText, setTypedText] = useState('');

  useEffect(() => {
    let currentIndex = 0;
    const interval = setInterval(() => {
      if (currentIndex < textToType.length) {
        setTypedText(textToType.slice(0, currentIndex + 1));
        currentIndex++;
      } else {
        clearInterval(interval);
      }
    }, 50); // Adjust typing speed as needed

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="blog-animation">
      <div className="blog-text">{typedText}</div>
    </div>
  );
};

export default BlogAnimation;