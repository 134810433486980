import React from "react";
import './Share.scss';

/**
 * @typedef {Object} ShareProps
 * @property {string} title - The title of the shared content.
 * @property {string} description - The description of the shared content.
 * @property {string} url - The URL of the shared content.
 */
function ShareComponent({ description }) {
    const url = window.location.href;

    // eslint-disable-next-line no-unused-vars
    function ShareWebAPI() {
        if (navigator.share) {
            navigator.share({
                title: description,
                text: description,
                url: url,
            })
                .catch(err => alert("Error Sharing: " + err.message));
        }
    }

    return (
            <div className="share-icon-collections">
                {/* Facebook */}
                <a href={`https://www.facebook.com/sharer/sharer.php?u=${url}`} target="_blank" rel="noreferrer">
                    <svg className="share-icon" xmlns="http://www.w3.org/2000/svg" style={{ width: '20', margin: '1rem' }} viewBox="0 0 600 512" aria-label="fb" role="img">
                        <path d="m375.14,288l14.22,-92.66l-88.91,0l0,-60.13c0,-25.35 12.42,-50.06 52.24,-50.06l40.42,0l0,-78.89s-36.68,-6.26 -71.75,-6.26c-73.22,0 -121.08,44.38 -121.08,124.72l0,70.62l-81.39,0l0,92.66l81.39,0l0,224l100.17,0l0,-224l74.69,0z"></path></svg>
                </a>

                {/* Twitter */}
                <a href={`https://twitter.com/intent/tweet/?text=${encodeURI(description)}&url=${url}`} target="_blank" rel="noreferrer">
                    <svg className="share-icon" xmlns="http://www.w3.org/2000/svg" style={{ width: '20', margin: '1rem' }} viewBox="0 0 600 512" aria-label="tw" role="img">
                        <path d="m600,106.08c-22,9.7 -45.6,16.2 -70.4,19.1c25.4,-15.2 44.9,-39.3 54.1,-68.1c-23.8,14.1 -50.2,24.3 -78.3,29.8c-22.5,-24 -54.5,-38.9 -90.1,-38.9c-68.2,0 -123.5,55.3 -123.5,123.5c0,9.7 1.1,19.2 3.2,28.3c-102.7,-5.1 -193.6,-54.4 -254.2,-129.3c-10.6,18.2 -16.7,39.3 -16.7,61.8c0,42.7 21.8,80.4 54.9,102.6c-20.2,-0.6 -39.2,-6.2 -55.8,-15.4c0,0.5 0,1 0,1.5c0,59.5 42.3,109.1 98.5,120.5c-10.3,2.8 -21.2,4.3 -32.4,4.3c-7.9,0 -15.6,-0.8 -23.1,-2.3c15.6,48.7 60.8,84.2 114.4,85.3c-41.9,32.8 -94.9,52.3 -152.6,52.3c-9.9,0 -19.7,-0.6 -29.4,-1.7c54.5,34.9 119.3,55.3 188.7,55.3c226.4,0 350.1,-187.4 350.1,-350.1l-0.41,-15.93c24.1,-17.4 45.1,-39.1 61.6,-63.8z"></path></svg>
                        </a>

                {/* LinkedIn */}
                <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${encodeURI(description)}`} target="_blank" rel="noreferrer">
                    <svg className="share-icon" xmlns="http://www.w3.org/2000/svg" style={{ width: '20', margin: '1rem' }} viewBox="0 0 600 512" aria-label="li" role="img">
                        <path d="m511.99999,511.99999l-0.00001,-0.00001l-0.00001,0z"></path><path d="m480,0l-384,0c-52.8,0 -96,43.2 -96,96l0,320c0,52.8 43.2,96 96,96l384,0c52.8,0 96,-43.2 96,-96l0,-320c0,-52.8 -43.2,-96 -96,-96zm-336,416l-64,0l0,-224l64,0l0,224zm-32,-256c-17.6,0 -32,-14.4 -32,-32c0,-17.6 14.4,-32 32,-32c17.6,0 32,14.4 32,32c0,17.6 -14.4,32 -32,32zm336,256l-64,0l0,-120c0,-28.8 -24.8,-52.8 -52.8,-52.8c-28.8,0 -52.8,24.8 -52.8,52.8l0,120l-64,0l0,-224l64,0l0,32c14.4,-22.4 40,-32 64,-32c56.8,0 96,40 96,112l0,112z"></path></svg>
                        </a>

                {/* Email */}
                <a href={`mailto:info@example.com?&subject=You+have+to+See+this!&cc=&bcc=&body=Check+out+this+site:${url}\n${encodeURI(description)}`} target="_blank" rel="noreferrer">
                    <svg className="share-icon" xmlns="http://www.w3.org/2000/svg" style={{ width: '20', margin: '1rem' }} viewBox="0 0 600 512" aria-label="mail" role="img">
                        <path d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"></path></svg>
                </a>
                
            </div>
    )
}

export default ShareComponent;
