// api.js
import axios from "axios";

export function fetchDataWithAxios() {
  const config = {
    method: "get",
    url: "https://www.googleapis.com/blogger/v3/blogs/3976487151389696370/posts?key=AIzaSyA7Oz494wjCaEVfT1AcF5jOMcUyq_LTNJY",
  };

  return axios(config)
    .then((response) => {
      console.log(response);

      let postData = response.data.items.map((postData) => {
        let post = {
          id: postData.id,
          title: postData.title,
          published: postData.published,
          url: postData.url,
        };
        return post;
      });

      console.log(postData);
      return postData;
    })
    .catch(function (error) {
      console.log(error);
    });
}
