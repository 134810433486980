import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { MutatingDots } from "react-loader-spinner";
import { SpeedInsights } from "@vercel/speed-insights/react"
import Navbar from './components/navbar/Navbar.jsx';
import Mainpage from './pages/mainpage/Mainpage.jsx';
import Aboutpage from './pages/aboutpage/Aboutpage.jsx';
import BlogPage from './pages/blogpage/Blogpage.jsx';
import BlogPostPage from './pages/blogpostpage/BlogPostPage.jsx';
import AutoProject from './pages/autoproject/AutoProject.jsx';
import LifeProject from './pages/lifeproject/LifeProject.jsx';
import MicDropper from './pages/micdropper/Micdropper.jsx';
import CryptoProject from './pages/cryptoproject/CryptoProject.jsx';
import MomsMatchPage from './pages/momsmatchpage/MomsMatchPage.jsx';
import CloudBrewersPage from './pages/cloudbrewerspage/CloudBrewersPage.jsx';
import ContactPage from './pages/contactpage/ContactPage.jsx';
import './App.scss';


const App = () => {
const [loading, setLoading] = useState(false);

useEffect(() => {
  setLoading(true)
  setTimeout(() => {
    setLoading(false)
  }, 800);
}, []);
  
  function Loader() {
    return (
    <MutatingDots
    visible={true}
    height="100"
    width="100"
    color="#FFFFFF"
    secondaryColor="#d3f708"
    radius="12.5"
    ariaLabel="mutating-dots-loading"
    wrapperStyle={{}}
    wrapperClass="loader"
    loading={loading}
    />
    );
    }
 
  return (
    <div id="background_container" className={`App ${loading ? 'loader-background' : ''}`}>  
    <Router>
      <Navbar/>
        <Switch>
        {
        loading ? 
        
        <Loader/>
        : null}
          <Route exact path="/"><Mainpage/></Route>
          <Route path="/work/AutoProject" exact component={AutoProject}/>
            <Route path="/work/LifeProject" exact component={LifeProject}/>
            <Route path="/work/CryptoProject" exact component={CryptoProject}/>
            <Route path="/work/MomsMatch" exact component={MomsMatchPage}/>
            <Route path="/work/CloudBrewers" exact component={CloudBrewersPage}/>
            <Route path="/work/mic-dropper" exact component={MicDropper}/>
          <Route exact path="/about"><Aboutpage/></Route>
          <Route  path="/blog" exact component={BlogPage}/>
          <Route path="/blog/:id/:url" exact component={BlogPostPage}/>
          <Route exact path="/contact"><ContactPage/>
          <SpeedInsights/>
          </Route>
        </Switch>
    </Router>
    </div>
  );
};

export default App;
