import React, { useEffect } from 'react';
import './AutoProject.scss';
import * as THREE from "three";
import HALO from "vanta/dist/vanta.halo.min";
import Navigation from '../../components/navigation/Navigation';
import auto1 from "../../assets/images/auto-1.png";
import auto2 from "../../assets/images/auto-2.png";
import auto3 from "../../assets/images/auto-3.png";
import auto4 from "../../assets/images/auto-4.png";
import auto5 from "../../assets/images/auto-5.png";
import auto6 from "../../assets/images/auto-6.png";
import auto7 from "../../assets/images/auto-7.png";
import auto8 from "../../assets/images/auto-8.png";
import auto9 from "../../assets/images/auto-9.png";
import auto10 from "../../assets/images/auto-10.png";
import auto11 from "../../assets/images/auto-11.png";


function AutoProject() {

      useEffect(() => {

        const vantaEffect = HALO({
          THREE,
          el: "#background_container",
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.00,
          minWidth: 200.00,
          backgroundColor: 0x0,
          baseColor: 0x1a59,
          size: 1,
          amplitudeFactor: 1,
          xOffset: 0,
          yOffset: 0
        });
    
        return () => {
          if (vantaEffect) vantaEffect.destroy();
        };
      }, []);
  const images = [auto1, auto2, auto3, auto4, auto5, auto6, auto7, auto8, auto9, auto10, auto11];

  return (
      <section className="auto-project-page">
      <div className="project-header">
        <h1 className="project-title">Auto Insurance</h1>
        <i>Web Development, Redesign, Web Design</i>
        <div className="project-description">
        <div className="boxy">
        <h2>Project Overview</h2>
        <p>This project is a comprehensive revamp of the UI Design, meticulously aligning it with Policysense’s fresh 
        UI aesthetics. The objective was to craft a visually appealing and intuitive UX experience that  
        guides the user through the platform.
        </p>
        </div>
        <div className="boxy">
        <h2>My tech stack </h2>
            <div className="about-especs">
            <p>
                <p><i>for REDESIGN AND WEB DESIGN and DEVELOPMENT</i></p>
                <div className="techStack">CSS3, JAVASCRIPT, VELO CODE, WIX, ZAPIER, CANVA & FIGMA</div>
                </p>
                </div>
          <p>The technical backbone of this transformation was built using Javascript, supplemented with Velo Packages. 
        A significant part of this overhaul was the integration of Zapier, which leverages Policysense’s Zapier connectors.
        Zapier integrations play a pivotal role in automating workflows, enhancing efficiency and productivity.In the context 
        of an insurance company like Policysense, these automations are crucial. 
        </p>
        </div>
        <div className="boxy">
        <h2>Key Features</h2>
<p><b>Aesthetic and Functional Enhancements:</b> The transformation focused on both visual appeal and user experience, 
ensuring the platform is intuitive and visually engaging.</p>
<p><b>Automation:</b> Integration of Zapier, leveraging Policysense’s connectors, to automate workflows and enhance efficiency, 
streamlining processes such as policy management and claim processing.</p>
</div>
        <div className="boxy">
        <h2>Objective</h2>
        <p>To streamline various processes such as policymanagement, claim processing, and customer service, 
        improving operational efficiency and enhancing the customer experience. 
        This technical enhancement reinforces Policysense's positiona as a competitive player in the tech insurance industry.</p>
        </div>
        </div>
      </div>
      <div className="boxy">
      <div className="gallery">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`auto-${index + 1}`}
            width="85%"
          />
        ))}
      </div>
      </div>
      <Navigation/>

    </section>
  );
}
export default AutoProject;