import React, { useEffect } from 'react';
import './Micdropper.scss';
import * as THREE from "three";
import HALO from "vanta/dist/vanta.halo.min";
import Navigation from '../../components/navigation/Navigation';
import mic0 from "../../assets/images/mic-0.png";
import mic1 from "../../assets/images/mic-1.png";
import mic2 from "../../assets/images/mic-2.png";
import mic3 from "../../assets/images/mic-3.png";
import mic4 from "../../assets/images/mic-4.png";
import mic5 from "../../assets/images/mic-5.png";
import mic6 from "../../assets/images/mic-6.png";
import mic7 from "../../assets/images/mic-7.png";
import mic8 from "../../assets/images/mic-8.png";



function MicProject() {

    useEffect(() => {

      const vantaEffect = HALO({
        THREE,
        el: "#background_container",
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: 200.00,
        minWidth: 200.00,
        backgroundColor: 0x0,
        baseColor: 0x1a59,
        size: 1,
        amplitudeFactor: 1,
        xOffset: 0,
        yOffset: 0
      });
  
      return () => {
        if (vantaEffect) vantaEffect.destroy();
      };
    }, []);
const images = [mic1, mic2, mic3, mic4, mic5, mic6, mic7, mic8, mic0];

const BehanceButton = () => {
  window.open('https://www.behance.net/gallery/209706965/Mic-Dropper-UXUI-Design-Web-Development', "_blank");
};

const MicDropperWebsite = () => {
  window.open('https://micdropper-carolaine-bonks-projects.vercel.app/', "_blank")
}


return (
    <section className="auto-project-page">
    <div className="project-header">
      <h1 className="project-title">Mic Propper Project</h1>
      <i>Web Development, UX/UI Design, Web Design</i>
      <div className="project-description">
      <div className="boxy">
      <h2>Project Overview</h2>
      <p>This project involves the creation of a platform tailored for rap battles, 
      designed to offer an immersive and competitive environment for rappers. 
      The focus was on building a responsive, music-industry-centric interface that highlights rapper competitions,
      engages the audience, and showcases global rankings. The UI was crafted to provide an intuitive and energetic user experience.
      </p>
      </div>
      <div className="boxy">
      <h2>My tech stack </h2>
          <div className="about-especs">
          <p>
              <p><i>for UX/UI DESIGN and WEB DEVELOPMENT</i></p>
              <div className="techStack">TAILWIND CSS, JAVASCRIPT, NEXT.JS 14, REACT & FIGMA</div>
              </p>
              </div>
        <p>The platform is designed and prototyped visually with figma, offering seamless user interactions 
        and a well-designed UI. The platform is built with dynamic elements such 
        as real-time rankings and voting systems for rap battles. A crucial part of the project 
        was building responsive components, ensuring a smooth experience across devices. 
        JavaScript to power interactive features.Tailwind CSS 
        made it easy to implement consistent styling, while React and Next.js ensured fast page loads and server-side 
        rendering and fast page loading, making it ideal for real-time content.
      </p>
      </div>
      <div className="boxy">
      <h2>Key Features</h2>
      <p><i>The project's key features include</i></p>
      <h3>Hero Section</h3>
<p><b>Responsive Design:</b>
A Spotify-inspired hero section, optimized for mobile and desktop,
that introduces the platform with a powerful heading, subheading, and call-to-action buttons.</p>
<p><b>Call to Action and Visual Appeal: </b>
Two buttons—"Get Started" and "Learn More"—encouraging new users to sign up and explore.
Engaging imagery and a dark, competitive theme tailored for the rap battle environment.</p>
<h3>Features Section</h3>
<p><b>Ranking System:</b>
Showcases top rappers across global regions,
offering users a way to see the top performers in their area and worldwide.</p>
<p><b>Highlighting Competitors:</b>
Engages users by featuring top competitors each week, keeping the platform dynamic and competitive.</p>
<h3>Stage Section</h3>
<p><b>Battle Rules: </b>
Interactive rule selection for each battle, allowing users to customize up to six rules, ensuring varied and engaging battles.</p>
<p><b>Video Content:</b>
Placeholder video cards for each round, ready for real-time video embeds, helping users follow each battle visually.</p>
<p><b>Voting System:</b>
Real-time voting distribution, showing which rapper is leading in each round, with a progress bar for visual feedback.</p>

</div>

      <div className="boxy">
      <h2>Additional Sections</h2>
      <p><i>for an Immersive and Competitive Rap Battle</i></p>
      <h3>Pricing Section</h3>
<p><b>Why Paid Participation?: </b>
A pricing model designed for competitors, offering features like high-quality video streaming, 
cloud storage, and analytics to track performance.</p>
<p><b>Two Pricing Tiers:</b>
Rookie and Pro with features that scale for different levels of competitors, 
ensuring each level gets the tools they need to succeed.</p>
      <h3>New Features Coming Soon Section</h3>
<p><b>Upcoming Features: </b>
A section that teases future updates, including a social media integration 
feature that allows users to share battle results and videos.</p>
<p><b>User Engagement and Interactive Layout:</b>
A "Get Notified" button encourages users to sign up for updates, ensuring continued interest in the platform.
Uses animations to highlight each upcoming feature, making the section visually engaging and dynamic.</p>
      </div>
      </div>
    </div>
    <div className="button-container">
                <button onClick={BehanceButton}>OPEN CASE STUDY</button>
                <button onClick={MicDropperWebsite}>CHECK WEBSITE</button>
                </div>
    <div className="boxy">
    <div className="gallery">
      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`auto-${index + 1}`}
          width="85%"
        />
      ))}
    </div>
    </div>
    <Navigation/>

  </section>
);
}
export default MicProject;