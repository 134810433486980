import React, { useEffect, useState } from "react";
import { useSpring, animated } from "@react-spring/web";
import { Link } from "react-router-dom";
import p5 from 'p5';
import TRUNK from "vanta/dist/vanta.trunk.min";
import BlogAnimation from "../../components/bloganimation/BlogAnimation";
import Navigation from '../../components/navigation/Navigation';
import { fetchDataWithAxios } from "./api";
import "./Blogpage.scss";


function BlogPage() {
  const [posts, setPosts] = useState([]);
  const props = useSpring({ opacity: 1, from: { opacity: 0.2 } });


  useEffect(() => {
    const vantaEffect = TRUNK({
      p5,
      el: "#background_container",
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.00,
      minWidth: 200.00,
      scale: 1.00,
      scaleMobile: 1.00,
      backgroundColor: 0x0,
    });  


  return () => {
    if (vantaEffect) vantaEffect.destroy();
  };
}, []);

  
  useEffect(() => {

    const fetchPosts = async () => {
      try {
        const response = await fetchDataWithAxios();
        console.log("API Response:", response);

        if (Array.isArray(response) && response.length > 0) {
          // Assuming 'items' is the array of blog posts in the response
          console.log("Fetched posts:", response)
          setPosts(response);
        } else {
          console.log("No posts found");
        }
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

       fetchPosts();
  }, []);

  return (
    <div className="blog-page">
        <div className="blog-title">Bonk's Blog
        <div className="boxy">
              <div className="animation">
                <BlogAnimation />
              </div>
            <div className="about-text">
              Here you'll find my writing on topics ranging from coding and the
              web industry, to entrepreneurship in the age of AI. My love for
              writing took flight while tackling a paper on International
              Entrepreneurship, Accelerators, Startups and Effectuation Theory
              back in University:{" "}
              <a
                className="paper-link"
                href="https://www.researchgate.net/profile/Carlos-Augusto-Septimio-De-Carvalho/publication/320934341_Effectuation_and_the_influence_of_social_networks_on_the_internationalization_of_accelerated_startups/links/5a034abcaca2721d41865f8e/Effectuation-and-the-influence-of-social-networks-on-the-internationalization-of-accelerated-startups.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                read here
              </a>
            </div>
          </div>
        </div>
          <div className="blog-container">
            {console.log("Posts to display", posts)}
              {posts.length > 0 ? (
              posts.map((post) => {
                  if (!post || typeof post !== 'object' || !post.id || !post.url || !post.title) { 
                    console.log("Unexpected post structure:", post); //4 debugging
                    return null;
            }
            return (
                    <div className="blog-overlay" key={post.id}>
                      <div className="blog-post">
                        <Link
                          to={{
                            pathname: `/blog/${post.id}/${post.url.split("/").pop()}`,
                            state: { postId: post.id },
                          }}>
                    <div className="post-title">
                    <p>{post.title}</p>
                        </div>
                        </Link>
                      </div>
                    </div>
            );
            })
              )  : (
               
                <animated.h1 style={props} className="recent-post">
                Coming Soon!
                </animated.h1>   
            )}
        </div>
        {/* <section className="lets-talk">
          <div className="boxie">
          <div className="projects-title">
                <i>Let's build something together?</i>
            </div>
          <div className="button-container">
          <Link to="/contact">
              <button>Get In touch</button>
              </Link>
              <Navigation/>
          </div>
          </div>
          </section> */}
          <Navigation/>
    </div>
  );
}

export default BlogPage;
