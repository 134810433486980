import React, { useEffect } from 'react';
import './LifeProject.scss';
import * as THREE from "three";
import HALO from "vanta/dist/vanta.halo.min";
import Navigation from '../../components/navigation/Navigation';
import life1 from "../../assets/images/life-1.png";
import life2 from "../../assets/images/life-2.png";
import life3 from "../../assets/images/life-3.png";
import life4 from "../../assets/images/life-4.png";
import life5 from "../../assets/images/life-5.png";
import life6 from "../../assets/images/life-6.png";
import life7 from "../../assets/images/life-7.png";
import life8 from "../../assets/images/life-8.png";



function LifeProject() {

  useEffect(() => {
    const vantaEffect = HALO({
      THREE,
      el: "#background_container",
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.00,
      minWidth: 200.00,
      backgroundColor: 0x0,
      baseColor: 0x1a59,
      size: 1,
      amplitudeFactor: 1,
      xOffset: 0,
      yOffset: 0
    });

    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, []);

  const images = [life1, life2, life3, life4, life5, life6, life7, life8];

    return (
        <>
        <section className="life-project-page">
            <div className="project-header">
                <h1 className="project-title">Life Insurance</h1>
                <i>Web Development, Redesign, Web Design</i>
                <div className="project-description">
                <div className="boxy">
                <h2>Project Overview</h2>
                <p>This new user experience (UX) was tailored to align with the company’s redesigned direction. 
        Executed predominantly on the WIX platform, the technical foundation leverages Javascript,
        Velo Packages and Zapier integrations utilizing Policysense's dedicated connectors. </p>
        </div>
        <div className="boxy">
            <h2>My tech stack </h2>
            <div className="about-especs">
            <p>
                <p><i>for REDESIGN WEB DESIGN AND DEVELOPMENT</i></p>
                <div className="techStack">CSS3, JAVASCRIPT, VELO CODE, WIX, ZAPIER, CANVA & FIGMA</div>
                </p>
                </div>
                </div>
                <div className="boxy">
        <h2>Key Features</h2>
        <p><b>Workflow Optimization:</b> 
        Zapier integrations were strategically deployed to automate 
        critical processes such as policy management and claims processing.
        </p>
        <p><b>Modern Aesthetic:</b> 
        The project aimed to deliver a high-quality, user-friendly interface with a contemporary look and feel.
        </p>
        </div>
        <div className="boxy">
        <h2>Objective</h2> 
        <p>This transformation not only focused on aesthetic upgrades but also on workflow
        optimization. Zapier integrations were strategically deployed to automate critical processes such
        as policy management and claims processing.</p> 
        <p>This technical enhancement not only enhances operational
        efficiency but also reinforces Policysense's standing as an innovative leader in the life insurance sector.    
        </p>
        </div>
        </div>
            </div>
            <div className="boxy">
            <div className="gallery">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`life-${index + 1}`}
            width="85%"
            margin="1rem"
          />
        ))}
      </div>
      </div>
      <Navigation/>
      </section>
        </>
    );
}

export default LifeProject;