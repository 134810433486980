import React, { useState } from "react";
import { Link } from "react-router-dom"; 
import cvPath from "../../assets/docs/Carolaine_Bonk_Resume-2024.2.pdf";
import "./Navbar.scss"; 

const Navbar = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const openCv = () => {
    window.open(cvPath, "_blank");
  };

  const openCvAndCloseMenu = () => {
    openCv();
    closeMenu();
  };


  return (
    <nav className="navbar">
      <div>
        <ul>
        <a href="https://www.credly.com/users/carolaine-bonk/badges" target="blank" className="social-link"><i className="fa-solid fa-certificate"></i></a>
            <a href="https://github.com/carolbonk" target="blank" className="social-link"><i className="fab fa-github icon"></i></a>
            <a href="https://www.linkedin.com/in/carolaine-bonk" target="blank" className="social-link"><i className="fab fa-linkedin-in icon"></i></a>
            <a href="https://www.behance.net/carolbonk" target="blank" className="social-link"><i className="fab fa-behance"></i></a>
        </ul>
        </div>
      <div className={`navbar-menu ${isMenuOpen ? "active" : ""}`}>
        <ul className="navbar-items">
          <li>
            <Link to="/" onClick={closeMenu}>
              Home + Work</Link>
          </li>
          <li>
            <Link to="/about" onClick={closeMenu}>
              My Experiences</Link>
          </li>
          <li>
            <Link to="/" onClick={openCvAndCloseMenu}>
              Open Resume (CV)</Link>
          </li>
          <li>
            <Link to="/blog" onClick={closeMenu}>
              Bonk's Blog</Link>
          </li>
          <li>
            <Link to="/contact" onClick={closeMenu}>
              Contact Me</Link>
          </li>
        </ul>
      </div>

      <div className={`navbar-burger ${isMenuOpen ? "active" : ""}`} onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </nav>
  );
};

export default Navbar;

