import React, { useRef, useEffect } from 'react'
import Iframe from 'react-iframe'
import "./FigmaEmbed.scss";

const FigmaEmbed = () => {
  const iframeRef = useRef(null);

useEffect(() => {
function handleResize() {
    if (iframeRef.current) {
        const containerWidth = iframeRef.current.parentElement.offsetWidth;
        const aspectRatio = 9/16;
        iframeRef.current.stylee.width = `${containerWidth}px`;
        iframeRef.current.style.height = `${containerWidth * aspectRatio}px`;
    }
};

window.addEventListener("resize", handleResize);
handleResize();

return () => {
    window.removeEventListener("resize", handleResize);
};
}, []);
  return (
    <Iframe
    ref={iframeRef}
    title="Figma Prototype" // A descriptive title for accessibility
    url="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FtmG5qYDFfnRLRlwt0Gkjvz%2Fmoms-match%3Fnode-id%3D372-1057%26t%3D1wRXwpwc33AmRpuj-1%26scaling%3Dscale-down%26content-scaling%3Dfixed%26page-id%3D0%253A1%26starting-point-node-id%3D372%253A1057"
    width="90%"
    height="90%"
    styles={{ border: "1px solid rgba(0, 0, 0, 0.1)" }}
    allowFullScreen
  />
  )
}

export default FigmaEmbed