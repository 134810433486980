import React, { useRef, useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";
import { Parallax, ParallaxLayer } from '@react-spring/parallax';
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import HALO from "vanta/dist/vanta.halo.min";
import * as THREE from "three";
import "./Mainpage.scss";
import autoimage from "../../assets/images/auto-1-tn.png";
import lifeimage from "../../assets/images/life-1-tn.png";
import momsimage from "../../assets/images/moms-1-tn2.png";
import cryptobud from "../../assets/images/cbud-tn2.png";
import cloudimage from "../../assets/images/cloud-brewers-tn2.png";
import micimage from "../../assets/images/mic-2-tn.png";


const projects = [

  {
    id: 1,
    title: "Cloud Brewers",
    subTitle: "Web Development and UX Design",
    image: cloudimage,
    description: "",
    link: "/Work/CloudBrewers",
  },
  {
    id: 2,
    title: "Moms Match",
    subTitle: "Product Design and UX Design",
    image: momsimage,
    description: "UX/UI Study Case of a matching app for moms",
    link: "/Work/MomsMatch",
  },
  {
    id: 3,
    title: "Auto Insurance",
    subTitle: "Web Development and Web Design",
    image: autoimage,
    description: "",
    link: "/Work/AutoProject",
  },
  {
    id: 4,
    title: "Life Insurance",
    subTitle: "Web Development and Web Design",
    image: lifeimage,
    description: "",
    link: "/Work/LifeProject",
  },
  {
    id: 5,
    title: "CryptoBud",
    subTitle: "Full Stack Web Development and Web Design",
    image: cryptobud,
    description: "",
    link: "/Work/CryptoProject",
  },
  {
    id: 6,
    title: "Mic Dropper",
    subTitle: "Web Development, UX/UI Design, Product Design",
    image: micimage,
    description: "",
    link: "/Work/mic-dropper",
  },
];

// const getParallaxValues = () => {
//   const width = window.innerWidth;

//   if (width <= 768) {
//     // Mobile
//     return { offset: 3.05, speed: 0.35, factor: 3.4 };
//   } else if (width <= 1024) {
//     // Tablet
//     return { offset: 2.85, speed: 0.7, factor: 3.08 };
//   } else {
//     // Desktop
//     return { offset: 2.5, speed: 0.45, factor: 3.25 };
//   }
// };

function MainPage() {

const parallaxRef = useRef(null);
const props = useSpring({opacity: 0.5 && 1, from: {opacity: 0 && 0.3}})
const workRef = useRef(null);

const variant = {
  visible: {scale: 0.3 && 1 },
  hidden: { scale: 0 },
}

const handleAnimate = async () => {
  animated("target", { opacity: 0.5 })
};

  useEffect(() => {
    const vantaEffect = HALO({
      THREE,
      el: "#background_container",
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.00,
      minWidth: 200.00,
      backgroundColor: 0x0,
      baseColor: 0x1a59,
      size: 1,
      amplitudeFactor: 1,
      xOffset: 0,
      yOffset: 0
    });

    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, []);

// const { offset, speed, factor} = getParallaxValues();

  return (
    <>
      <div className="main-container">
      <Parallax ref={parallaxRef} pages={4.2}>
      <ParallaxLayer 
      className="center"
      speed={0.05}
      offset={0}
      factor={1}
      onClick={()=> ('')}>
          <section id="home">
        <div id="target" className="hero-container">
          <div className="name">
            <animated.h1 style={props} className="hi">Olá,</animated.h1>
            <div className="bonk">I'm Carolaine Bonk</div>
            <h4 className="sub-name">Web Developer & UX Designer</h4>
          </div>
          <div className="boxy">
            <div className="about-text">
            A Miami based creative Front-End Developer & UX Designer. 
            Currently freelancing, building web applications with Javascript, 
            while seeking for a full-time role.  
            
            {/* [![My Skills](https://skillicons.dev/icons?i=figma,html,css,sass,
            javascript,react,bootstrap,nextjs,threejs,vscode,dart,flutter
            ,webflow,wordpress,nodejs,nestjs,express,mysql,mongodb,postman,
            firebase,git,github,heroku,netlify,vercel)](https://skillicons.dev)        */}
            
            </div>
          </div>
          <div className="button-container">
              <button onClick={() => parallaxRef.current.scrollTo(1)}>
              CHECK MY WORK</button>
            <Link to="/about">
              <button onClick={() => handleAnimate()}>EXPERIENCES</button>
            </Link>
          </div>
        </div>
        </section>
        </ParallaxLayer>

      <ParallaxLayer 
      offset={1.2} 
      speed={0.45}
      factor={2.4}
      style={{
        backgroundSize: 'cover', backgroundBlur:5,
      }} 
      onClick={()=> ('')}>

          <section id="work" ref={workRef}>
        <div className="projects-sec">
          <div className="projects-title">
            <div className="subTitle_work">Work Archive</div>
            <animated.i style={props}>Web Development and UX Design Projects</animated.i>
          </div>
          <div className="boxy">
          <div className="about-text_projects">

              Here are some of the projects I have worked on. 
              I have a pragmatic approach to Web Development and UX Design, 
              focusing on delivering it on a accessible and optimised way.
              I'm happy to deliver anything from single-page to a scaleable design system.
              I believe that a good interactive design grounds on a solid understanding of the user's needs, 
              combined with a strong foundational sense of space and logic. 
          </div>
          </div>
          <div className="projects-container">
            {projects.length > 0
              ? projects.map((project) => (
                  <div className="project-container" key={project.id}>
                    <div className="project-overlay">
                      <div className="project-title">{project.title}
                      <h4 className="projects-subTitle">{project.subTitle}</h4>
                      </div>
                      <Link to={project.link}>
                        <motion.img
                          src={project.image}
                          alt={project.title}
                          variants={variant}
                          initial="hidden"
                          whileInView="visible"
                          className="project-pic"
                        />
                      </Link>
                    </div>
                  </div>
                ))
              : projects.length.setAttribute("tabindex", "0")}
               
              </div>
              <div className="lets-talk">
              <div className="button-up">
              <button onClick={() => parallaxRef.current.scrollTo(0)}>
              GO BACK UP
            </button>
            </div>
          </div>
          </div>
          </section>
         {/* </ParallaxLayer> 

         <ParallaxLayer 
         className="center"
         offset={offset}
            speed={speed}
            factor={factor}
      style={{ backgroundSize: 'cover',
      }} onClick={()=> ('')}
      >  */}
          {/* <section className="lets-talk">
          <div className="boxie">
          <div className="button-container">
          <div className="projects-title">
                  <p>What would you like to do next?</p>
                  <p>
                    <i>Let's build something together</i>
                  </p>
                </div>
                <div className="button-container">
                  <Link to="/contact">
                    <button>Get In touch</button>
                  </Link>
              <button onClick={() => parallaxRef.current.scrollTo(0)}>
              GO BACK UP
            </button>
          </div>
          </div>
          </div>
          </section> */}
        </ParallaxLayer>    
        </Parallax>
      </div>
    </>
  );
}

export default MainPage;
